import { useMemo } from "react";
import { Button, HeightIcon } from "@androshq/shared-ui";
import { GridApi } from "ag-grid-community";
import { measures } from "../../../api/cube";
import { getVendorApprovalStatus } from "../../../api/standards";
import { ItemWithConfig } from "../../../api/types";
import BarChart from "../../../components/cards/charts/BarChart";
import MetricsCard from "../../../components/cards/metrics-card/MetricsCard";
import Tooltip from "../../../components/messages/Tooltip";
import VendorSummaryTable from "../../../components/tables/SupplyTopTable";
import {
  SupportedGranularityOptions,
  buildDateRangeOutwardFromNextPeriodStart,
} from "../../../components/tables/standards";
import { useFetchStates } from "../../../hooks/states/useFetchStates";

interface Props {
  item: ItemWithConfig;
  gridApi: GridApi | null;
}

export default function SupplyPlanningTopSection({ item, gridApi }: Props) {
  const { data: vendors } = useFetchStates({ state_type: "vendor" });

  const mappedData = useMemo(() => {
    if (!item || !vendors) return;

    return (
      Object.keys(item.config.vendor).map((key) => {
        const vendor = item.config.vendor[key];
        const vendorNameObject = vendors.find((vendor: { id: number }) => vendor.id.toString() === key);
        return {
          vendor: vendorNameObject?.name,
          leadTime: vendor.vendor_time_days + vendor.shipment_time_days,
          // incomePerMonth: null,
          lotSize: item.config.item_lot_size,
          minOrder: vendor.minimum_order_quantity,
          weekQty: vendor.max_weekly_item_capacity,
          approved: getVendorApprovalStatus(vendor),
          preferred: vendor.vendor_preferred_for_item,
        };
      }) || []
    );
  }, [item, vendors]);

  const reorderRows = () => {
    if (!gridApi) return null;

    const rowDataToSort: any[] = [];
    gridApi.forEachNode((node) => rowDataToSort.push(node.data));
    const sortedData = rowDataToSort.sort((a, b) => {
      if (a.sourceRowName === "Balance" && b.sourceRowName === "Balance") {
        return a.isMainRevision === b.isMainRevision ? 0 : a.isMainRevision ? -1 : 1;
      }

      if (a.sourceRowName !== "Balance" && b.sourceRowName !== "Balance") {
        const aTxnDate = new Date(a.txnDate).getTime();
        const bTxnDate = new Date(b.txnDate).getTime();
        return aTxnDate - bTxnDate;
      }

      return a.sourceRowName === "Balance" ? -1 : 1;
    });

    gridApi.setGridOption("rowData", sortedData);
  };

  return (
    <div className="flex gap-2.5 justify-between flex-wrap ">
      <div className="flex-1 flex flex-col">
        <div className="borderborder-gray-200 shadow p-2 rounded-xl flex-[3]">
          <VendorSummaryTable rows={mappedData} isLoading={vendors ? false : true} />
        </div>
        <div className="flex-1 flex items-end">
          <Tooltip tooltip="Reorder based on txn date">
            {/* @ts-expect-error FIX in shared-ui */}
            <Button onClick={reorderRows} className="mb-2 flex items-center gap-1.5" variant="outline">
              <HeightIcon />
              Reorder Lines
            </Button>
          </Tooltip>
        </div>
      </div>
      <MetricsCard
        title="Current Forecast vs Historical Performance"
        granularity={SupportedGranularityOptions.month}
        granularityIsFixed={true}
        dateRange={buildDateRangeOutwardFromNextPeriodStart(6, 5, SupportedGranularityOptions.month)}
        dateRangeIsFixed={true}
        measures={[measures.actual.total, measures.forecast.leadTime.total, measures.forecast.mostRecent.total]}
        itemList={[item]}
        contentComponent={BarChart}
      />
    </div>
  );
}
