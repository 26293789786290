import { useEffect, useMemo } from "react";
import { Query } from "@cubejs-client/core";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { measures } from "../../../../api/cube";
import {
  ITEM_FCST_PAGE_TS_COL_QTY,
  ITEM_FCST_PAGE_TS_MIN_WEEK_NUM,
  getDateRangeForCubeQuery,
} from "../../../../components/tables/standards";
import { useCompanies } from "../../../../hooks/companies/useCompanies";
import {
  ROLLFORWARD_TS_COL_QTY,
  ROLLFORWARD_TS_MIN_WEEK_NUM,
} from "../../../../hooks/items/list-views/useFetchInventoryRollforward";
import { useItems } from "../../../../hooks/items/useItems";
import { selectAppData } from "../../../../redux/appSlice";
import { useSopSelector } from "../../../../redux/reducer";
import forecastRecommendations from "../../../../utils/columnDefs/forecastRecommendations";
import inventoryRollforward from "../../../../utils/columnDefs/inventoryRollforward";
import { mapForecastRecommendationsFromResultSets, mapInventoryRollforwardFromResultSets } from "../utils";
import { useCubeQueries } from "./useCubeQueries";

export enum View {
  ForecastRecommendations = "forecast_recommendations",
  InventoryRollforward = "inventory_rollforward",
}

const columnDefsMap = {
  [View.ForecastRecommendations]: forecastRecommendations,
  [View.InventoryRollforward]: inventoryRollforward,
};
const mapperMap = {
  [View.ForecastRecommendations]: mapForecastRecommendationsFromResultSets,
  [View.InventoryRollforward]: mapInventoryRollforwardFromResultSets,
};
const rowHrefMap = {
  [View.ForecastRecommendations]: (id: number) => `/sop/items/${id}/forecasting`,
  [View.InventoryRollforward]: (id: number) => `/sop/items/${id}/supply-chain`,
};

const queryBuilder = ({ companyIds }: { companyIds: string[] }): { [key: string]: Query[] } => {
  const granularity = "week";

  return {
    [View.ForecastRecommendations]: [
      {
        measures: [measures.actual.totalAvg, measures.forecast.leadTime.totalAvg],
        timeDimensions: [
          {
            dimension: "weeks.week",
            dateRange: "last 12 weeks",
          },
        ],
        filters: [
          {
            member: "items.company_id",
            operator: "equals",
            values: companyIds,
          },
        ],
        dimensions: ["items.id"],
      },
      {
        measures: [
          measures.forecast.mostRecent.total,
          measures.forecast.mostRecent.recommended,
          measures.forecast.mostRecent.fixed,
        ],
        timeDimensions: [
          {
            dimension: "demand_forecast_most_recent.week",
            dateRange: getDateRangeForCubeQuery(ITEM_FCST_PAGE_TS_MIN_WEEK_NUM, ITEM_FCST_PAGE_TS_COL_QTY),
            granularity,
          },
        ],
        filters: [
          {
            member: "demand_forecast_most_recent.company_id",
            operator: "equals",
            values: companyIds,
          },
          {
            member: "demand_forecast_most_recent.active_cut_qty_item_sum_wtd",
            operator: "gt",
            values: ["0"],
          },
        ],
        dimensions: [
          "demand_forecast_most_recent.week",
          "demand_forecast_most_recent.company_id",
          "demand_forecast_most_recent.item_id",
          "demand_forecast_most_recent.active_cut_qty_item_sum_wtd",
        ],
        order: [["demand_forecast_most_recent.active_cut_qty_item_sum_wtd", "desc"]],
      },
    ],
    [View.InventoryRollforward]: [
      {
        measures: [
          "inventory_flows.inflow",
          "inventory_flows.inflow_cumulative",
          "inventory_flows.outflow",
          "inventory_flows.outflow_cumulative",
        ],
        dimensions: [
          "inventory_flows.item_id", // Item ID
          "inventory_flows.item_name", // Item Name
          "inventory_flows.item_name", // Item Name
          "inventory_flows.item_name_prefix", // Item Name Prefix
          "inventory_flows.item_variant", // Item [Name] Variant
          "inventory_flows.company_id", // Company ID
          "inventory_flows.company_name", // Company Name
          "inventory_flows.bal_snapshot_qty_all_loc",
          // TODO: Need a way to receive Min Item Inv Qty from cube
          // "inventory_flows.item_inventory_quantity_min", // Min Qty
        ],
        timeDimensions: [
          {
            dimension: "inventory_flows.date",
            granularity,
            dateRange: getDateRangeForCubeQuery(ROLLFORWARD_TS_MIN_WEEK_NUM, ROLLFORWARD_TS_COL_QTY),
          },
        ],
        order: [
          [
            "inventory_flows.item_id", // This is to make sure items aren't mixed in order
            "asc",
          ],
          [
            "inventory_flows.date", // Column order
            "asc",
          ],
        ],
        filters: [
          {
            member: "inventory_flows.company_id",
            operator: "equals",
            values: companyIds,
          },
        ],
      },
    ],
  };
};

export const useItemsView = (defaultView?: View) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: companies = [], isLoading: isCompaniesLoading } = useCompanies();
  const { data: items = [], isLoading: isItemsLoading } = useItems();
  const { selectedCompanyIds } = useSopSelector(selectAppData);

  const view = useMemo(
    () => defaultView ?? (searchParams.get("view") as View) ?? View.ForecastRecommendations,
    [defaultView, searchParams],
  );

  useEffect(() => {
    if (!searchParams.has("view")) {
      searchParams.set("view", View.ForecastRecommendations);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [navigate, searchParams]);

  const queries = useMemo(
    () =>
      selectedCompanyIds?.length == 0
        ? []
        : queryBuilder({
          companyIds: selectedCompanyIds,
        })[view],
    [selectedCompanyIds, view],
  );

  const { isLoading: isQueriesLoading, resultSets, refetch, ...queryData } = useCubeQueries(queries);
  const isLoading: boolean = isCompaniesLoading || isItemsLoading || isQueriesLoading;
  const mappedData = useMemo(() => mapperMap[view](resultSets!), [resultSets, view]);

  const data = useMemo(
    () =>
      mappedData?.flat().map((row) => {
        const item = _.find(items, { id: +row.item.id });

        return {
          ...row,
          item: {
            name: item?.name,
            name_prefix: item?.source_metadata.item_name_prefix,
            variant: item?.variant,
            ...row.item,
          },
          company: {
            name: _.find(companies, { id: +row.company.id })?.name,
            ...row.company,
          },
        };
      }),
    [mappedData, items, companies],
  );




  const columnDefs = useMemo(() => columnDefsMap[view], [view]);


  const getRowHref = useMemo(() => rowHrefMap[view], [view]);

  return { data, isLoading, refetch, view, selectedCompanyIds, getRowHref, ...columnDefs, ...queryData };
};
