import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Card, CardContent, CardHeader, ReloadIcon } from "@androshq/shared-ui";
import { cn } from "@androshq/shared-ui";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { WorkflowExecutionsSearchQueryParams } from "../../../../api";
import { Company, Item } from "../../../../api/types";
import { useFetchWorkflowExecutions } from "../../../../hooks/workflows/useFetchWorkflowExecutions";
import { defaultColDef, getColumnDefs } from "./columns";

interface Props {
  items: Item[];
  companies: Company[];
}

export default function WorkflowEventsDashboardTable({ items, companies }: Props) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), [setGridApi]);

  const { user } = useAuth();

  const filtersParams: WorkflowExecutionsSearchQueryParams = useMemo(() => {
    return {
      filters: {
        workflow_type_name: ["ChangeApprovalsWorkflow"],
        "search_attributes.approvers[*].user.id": [user?.profile.email || ""],
      },
      order_by: "last_modified",
      order_by_desc: true,
      limit: 100,
      offset: 0,
    } as WorkflowExecutionsSearchQueryParams;
  }, [user]);

  const { data: workflowExecutions, isLoading } = useFetchWorkflowExecutions(filtersParams);

  const rowData = useMemo(() => {
    return workflowExecutions?.sort((a, b) => {
      const dateA = new Date(a.state.event_history[0].time);
      const dateB = new Date(b.state.event_history[0].time);
      return +dateB - +dateA;
    });
  }, [workflowExecutions]);

  const columnDefs = useMemo(() => {
    if (rowData && rowData.length !== 0) {
      return getColumnDefs(items, companies);
    } else {
      return [];
    }
  }, [rowData, companies, items]);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (!rowData) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        columnDefs,
      });
    }
  }, [gridApi, isLoading, columnDefs, rowData]);

  return (
    <Card className={cn("flex w-full flex-col min-h-[350px] overflow-hidden")}>
      <CardHeader className="p-4 pb-0 z-10">
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-semibold">Most Recent Workflow Events</h3>
        </div>
      </CardHeader>
      <CardContent className="flex-1 p-0 pt-4">
        <div className={cn("ag-theme-quartz w-full h-full", { "ag-borders-none": !rowData?.length })}>
          <AgGridReact
            suppressAutoSize
            suppressCellFocus
            reactiveCustomComponents
            rowBuffer={25}
            loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
            noRowsOverlayComponent={() => <div>No workflows found where you`re an approver</div>}
            {...{ defaultColDef, onGridReady }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
