import { useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon } from "@androshq/shared-ui";
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

interface Props {
  rows:
    | {
        vendor: string | undefined;
        leadTime: number;
        // incomePerMonth: string | null;
        lotSize: number;
        minOrder: number | null;
        weekQty: number | null;
        approved: string;
      }[]
    | undefined;
  isLoading: boolean;
}

export default function SupplyTopTable({ rows, isLoading }: Props) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        children: [
          { headerName: "Vendor", flex: 1, field: "vendor" },
          { headerName: "Lead Time", flex: 1, field: "leadTime" },
        ],
      },
      {
        headerName: "Order Constraints",
        children: [
          { headerName: "Lot Size", flex: 1, field: "lotSize" },
          { headerName: "Min Order", flex: 1, field: "minOrder" },
        ],
      },
      {
        headerName: "Capacity",
        children: [{ headerName: "Week Qty", flex: 1, field: "weekQty" }],
      },
      {
        headerName: "",
        children: [{ headerName: "Approved", flex: 1, field: "approved", resizable: false }],
      },
      {
        headerName: "",
        children: [
          {
            headerName: "Preferred",
            flex: 1,
            field: "preferred",
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value === 0) {
                return "No";
              } else if (params.value === 1) {
                return "Yes";
              } else {
                return "Unknown";
              }
            },
            resizable: false,
          },
        ],
      },
    ],
    [],
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      resizable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      sortable: (rows?.length ?? 0) > 1,
    }),
    [rows],
  );

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (!rows) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData: rows,
        columnDefs,
      });
    }
  }, [gridApi, columnDefs, rows, isLoading]);

  return (
    <div className="ag-theme-quartz w-full h-full">
      <AgGridReact
        suppressCellFocus
        suppressAutoSize
        reactiveCustomComponents
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>No data available</div>}
        {...{ defaultColDef, onGridReady }}
      />
    </div>
  );
}
