import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { differenceInDays } from "date-fns";
import _ from "lodash";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "inventory_projected_shortages-company_name",
    headerName: "Company",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
  },
  {
    field: "item_name",
    headerName: "Item",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
            />
          );
        },
      };
    },
  },
  {
    field: "lead_time_order_date",
    headerName: "Order Date",
    flex: 1,
    valueGetter: (params) => {
      return {
        date_under_min: params.data["inventory_projected_shortages-date_under_min_inv_qty"],
        lead_time_days: params.data["inventory_projected_shortages-lead_time_days"],
        date_order_required: params.data["inventory_projected_shortages-date_required"],
        days_till_required: differenceInDays(params.data["inventory_projected_shortages-date_required"], new Date()),
      };
    },
    valueFormatter: (params) => {
      let relStr = "today";
      if (params.value.days_till_required == 1) {
        relStr = "tomorrow";
      } else if (params.value.days_till_required == -1) {
        relStr = "yesterday";
      } else if (params.value.days_till_required > 1) {
        relStr = `in ${params.value.days_till_required} days`;
      } else if (params.value.days_till_required < 1) {
        relStr = `${Math.abs(params.value.days_till_required)} days ago`;
      }
      return `${params.value.date_order_required} (${relStr})`;
    },
    cellClass: (params) => {
      if (params.value.days_till_required <= 0) {
        return "cursor-pointer text-orange-700";
      } else if (params.value.days_till_required <= 7) {
        return "cursor-pointer text-amber-500";
      } else {
        return "cursor-pointer";
      }
    },
  },
  {
    field: "lead_time_arrival_date",
    headerName: "Arrival Date",
    flex: 1,
    valueGetter: (params) => {
      return {
        date_under_min_inv_qty: params.data["inventory_projected_shortages-date_under_min_inv_qty"],
        lead_time_days: params.data["inventory_projected_shortages-lead_time_days"],
      };
    },
    cellClass: "cursor-pointer",
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <div className="inline-flex">
              <div className="mr-2 w-10 text-right">{params.value.date_under_min_inv_qty}</div>
              <span className="text-muted-foreground">{` in ${params.value.lead_time_days} days LT`}</span>
            </div>
          );
        },
      };
    },
  },
  {
    field: "lead_time_arrival_date",
    headerName: "Current Proj. Balance",
    flex: 1,
    valueGetter: (params) => {
      return {
        ending_balance: params.data["inventory_projected_shortages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_shortages-inv_qty_min_all_loc"],
      };
    },
    cellClass: "cursor-pointer",
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <div className="inline-flex">
              <div className="mr-2 w-8 text-right">{params.value.ending_balance}</div>
              <span className="text-muted-foreground">{` vs ${params.value.inv_qty_min_all_loc} min`}</span>
            </div>
          );
        },
      };
    },
  },
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "inventory_projected_shortages.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "inventory_projected_shortages.company_name",
      "inventory_projected_shortages.item_name",
      "inventory_projected_shortages.item_name_prefix",
      "inventory_projected_shortages.item_variant",
      "inventory_projected_shortages.item_id",
      "inventory_projected_shortages.date_under_min_inv_qty",
      "inventory_projected_shortages.date_required",
      "inventory_projected_shortages.lead_time_days",
      "inventory_projected_shortages.bal_snapshot_qty_all_loc",
      "inventory_projected_shortages.inflow_cumulative",
      "inventory_projected_shortages.outflow_cumulative",
      "inventory_projected_shortages.ending_balance",
      "inventory_projected_shortages.inv_qty_min_all_loc",
    ],
    order: [["inventory_projected_shortages.date_required", "asc"]],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Upcoming Required Order Dates",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No shortages found near lead time" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
