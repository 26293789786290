interface Props {
  item_name: string;
  item_name_prefix?: string;
  item_variant?: string;
}

export default function ItemNameCell({ item_name, item_name_prefix, item_variant }: Props) {
  return (
    <span>
      <span className="text-muted-foreground">{item_name_prefix || " "}</span>
      <span data-testid="item-cell">{item_name} </span>
      {item_variant && <span className="text-muted-foreground">({item_variant})</span>}
    </span>
  );
}
