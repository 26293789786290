import { useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardHeader, cn } from "@androshq/shared-ui";
import { BinaryFilter, BinaryOperator, DateRange, Query, TimeDimension } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import DataCardTable from "./outputs/DataCardTable";

export type DisplayConfig = {
  cardTitle: string;
  outputType: "table" | "chart";
  cardClassName?: string;
  noRowsMessage?: string;
  columnDefsCustom?: (ColGroupDef<any> | ColDef<any, any>)[]; // Will render default columns unless this is passed
};

type SupportedGranularities = "week" | "month";

type DynamicTimeDimensionInput = {
  dateRange: {
    inputDataKey: string;
  };
  granularity: {
    inputDataKey: string;
  };
  dimension: string;
};

type DynamicFilterInput = {
  inputDataKey: string;
  member: string;
  operator: BinaryOperator;
};

export type DynamicInputs = {
  timeDimensions: DynamicTimeDimensionInput[];
  filters: DynamicFilterInput[];
};

export type BuiltQueryConfig = {
  baseQuery: Query;
  dynamicInputs: DynamicInputs;
};

type InputDataItemDateRange = {
  inputDataType: "dateRange";
  disabled: boolean;
  selectedValue: DateRange;
};

export type InputDataItemSingleSelect = {
  inputDataType: "singleSelect";
  disabled: boolean;
  selectedId: number | string;
  options: { id: number | string; name: string }[];
};

type InputDataItemMultiSelect = {
  inputDataType: "multiSelect";
  disabled: boolean;
  selectedIds: number[] | string[];
  options: { id: number | string; name: string }[];
};

type InputConfig = InputDataItemDateRange | InputDataItemSingleSelect | InputDataItemMultiSelect;

export type InputConfigs = {
  [key: string]: InputConfig;
};

const buildQuery = (
  builtQueryConfigs: BuiltQueryConfig[],
  inputConfigs: InputConfigs,
  inputValues: { [key: string]: number | string | string[] | number[] },
) => {
  const queries: Query[] = [];

  for (const config of builtQueryConfigs) {
    const query = { ...config.baseQuery };
    const builtFilters: BinaryFilter[] = [];

    // Built filters from inputs and add to base query
    for (const dynamicInputFilter of config.dynamicInputs.filters) {
      if (inputConfigs[dynamicInputFilter.inputDataKey].inputDataType == "multiSelect") {
        builtFilters.push({
          operator: dynamicInputFilter.operator,
          values: (inputValues[dynamicInputFilter.inputDataKey] as number[]).map((id) => id.toString()),
          member: dynamicInputFilter.member,
        });
      } else {
        throw new Error("Unsupported");
      }
    }

    if (config.baseQuery.filters) {
      query.filters = [...config.baseQuery.filters, ...builtFilters];
    } else {
      query.filters = [...builtFilters];
    }

    // Build time dimensions from inputs and add to base query
    const builtTimeDimensions: TimeDimension[] = [];
    for (const dynamicInputTimeDimension of config.dynamicInputs.timeDimensions) {
      if (inputConfigs[dynamicInputTimeDimension.granularity.inputDataKey].inputDataType != "singleSelect") {
        throw new Error("Unsupported");
      }

      if (inputConfigs[dynamicInputTimeDimension.dateRange.inputDataKey].inputDataType != "dateRange") {
        throw new Error("Unsupported");
      }
      const timeDimension: TimeDimension = {
        dimension: dynamicInputTimeDimension.dimension,
        granularity: inputValues[dynamicInputTimeDimension.granularity.inputDataKey] as SupportedGranularities,
        dateRange: inputValues[dynamicInputTimeDimension.dateRange.inputDataKey] as DateRange,
      };

      builtTimeDimensions.push(timeDimension);
    }

    if (config.baseQuery.timeDimensions) {
      query.timeDimensions = [...config.baseQuery.timeDimensions, ...builtTimeDimensions];
    } else {
      query.timeDimensions = [...builtTimeDimensions];
    }

    // Add built query to builtQueries
    queries.push(query);
  }

  // If len is 1, just return first item in query list (this is a non-blended query)
  // Otherwise return full list
  if (queries.length == 1) {
    return queries[0];
  }
  return queries;
};

export type DataCardProps = {
  displayConfig: DisplayConfig;
  queryConfigs: BuiltQueryConfig[];
  inputConfigs: InputConfigs;
};

const buildSelectedValuesObject = (inputConfigs: InputConfigs) => {
  const keys = Object.keys(inputConfigs);
  const values = Object.values(inputConfigs).map((inputConfig) => {
    const selectedValueEntry = Object.entries(inputConfig).find((entry) => entry[0].startsWith("selected"));
    if (selectedValueEntry) {
      return selectedValueEntry[1];
    }
  });
  return keys.reduce(
    (obj, key, index) => {
      obj[key] = values[index];
      return obj;
    },
    {} as Record<string, number | string>,
  );
};

export default function DataCard(dataCardProps: DataCardProps) {
  const [cardInputs, setCardInputs] = useState(buildSelectedValuesObject(dataCardProps.inputConfigs));
  const navigate = useNavigate();

  const query = useMemo(() => {
    return buildQuery(dataCardProps.queryConfigs, dataCardProps.inputConfigs, cardInputs);
  }, [dataCardProps.queryConfigs, dataCardProps.inputConfigs, cardInputs]);

  const { resultSet, isLoading } = useCubeQuery(query);

  const onRowClicked = (event: RowClickedEvent) => {
    const itemIdKey = Object.keys(event.data).find((key) => key.endsWith("item_id"));
    const itemId = itemIdKey ? event.data[itemIdKey] : null;
    if (itemId) {
      const path = `/sop/items/${itemId}/supply-chain`;
      if (event.event instanceof PointerEvent && event.event.metaKey) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }
    }
  };

  useEffect(() => {
    const configInputsDisabledSubset = Object.fromEntries(
      Object.entries(dataCardProps.inputConfigs).filter(([, value]) => value.disabled),
    );

    setCardInputs((prevInputs) => ({
      ...prevInputs,
      ...buildSelectedValuesObject(configInputsDisabledSubset),
    }));
  }, [dataCardProps.inputConfigs]);

  return (
    <Card
      className={cn("flex flex-1 flex-col min-h-[350px] overflow-hidden", dataCardProps.displayConfig.cardClassName)}
    >
      <CardHeader className="p-4 pb-0 z-10">
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-semibold">{dataCardProps.displayConfig.cardTitle}</h3>
          {/* <div className="flex flex-wrap gap-3 min-h-6">
            {Object.entries(dataCardProps.inputConfigs).map(([inputKey, inputConfigObj]) => {
              return (
                <DataCardInput
                  key={inputKey}
                  inputKey={inputKey}
                  inputValue={cardInputs[inputKey]}
                  inputDataType={inputConfigObj.inputDataType}
                  selectedValue={"selectedValue" in inputConfigObj ? inputConfigObj.selectedValue : ""}
                  inputDisabled={inputConfigObj.disabled}
                  inputValueOptions={"options" in inputConfigObj ? inputConfigObj.options : []}
                  onChange={onDataInputChange}
                />
              );
            })}
          </div> */}
        </div>
      </CardHeader>
      <CardContent className="flex-1 p-0 pt-4">
        {dataCardProps.displayConfig.outputType === "table" && (
          <DataCardTable
            resultSet={resultSet}
            isLoading={isLoading}
            onRowClicked={onRowClicked}
            noRowsMessage={dataCardProps.displayConfig.noRowsMessage}
            columnDefsCustom={dataCardProps.displayConfig.columnDefsCustom}
          />
        )}
      </CardContent>
    </Card>
  );
}

// type InputValue = number | string | string[] | number[];
// type InputValueOptions = { id: string | number; name: string }[];

// function DataCardInput({
//   inputKey,
//   inputValue,
//   inputValueOptions,
//   inputDataType,
//   inputDisabled,
//   // selectedValue,
//   onChange,
// }: {
//   inputKey: string;
//   inputValue: InputValue;
//   inputValueOptions: InputValueOptions;
//   inputDataType: string;
//   selectedValue: DateRange;
//   inputDisabled: boolean;
//   onChange: (inputKey: string, newSelection: InputValue) => void;
// }) {
//   if (inputDataType === "singleSelect") {
//     return (
//       <SingleSelect
//         inputKey={inputKey}
//         inputValue={inputValue as string | number}
//         inputValueOptions={inputValueOptions}
//         inputDisabled={inputDisabled}
//         onChange={onChange}
//       />
//     );
//   }
//   if (inputDataType === "multiSelect") {
//     return <DataCardMultiSelect inputKey={inputKey} inputValues={inputValue} inputValueOptions={inputValueOptions} />;
//   }

//   if (inputDataType === "dateRange") {
//     return <DataCardMultiSelect inputKey={inputKey} inputValues={inputValue} inputValueOptions={inputValueOptions} />;
//   }
// }
