import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GroupCellRendererParams,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { ItemConfig, ShippingMethodEnum } from "../../../api/types";
import Tooltip from "../../../components/messages/Tooltip";
import LinkCell from "../../../components/tables/cells/LinkCell";
import { formatCellDate } from "../../../utils";
import { ActionsCell } from "./ActionsCell";
import { buildSourceLink } from "./helpers";
import { IRequisition } from "./types";
import { getCellEditorSelector, valueFormatter, valueNumberFormatter } from "./utils";

export * from "./ActionsCell";

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  valueFormatter,
};

const cellWidth = {
  SM: 50,
  MD: 80,
};

export const placeholderColumn = {
  width: 10,
  flex: 0,
  valueFormatter: (params: ValueFormatterParams) => params.value,
};

export const getEditableCellDateDefs = (conditions?: (params: EditableCallbackParams) => boolean[]): ColDef => {
  const editable = (params: EditableCallbackParams): boolean => {
    if (conditions && conditions(params)?.includes(false)) return false;
    return params.data?.editable;
  };

  return {
    cellClassRules: {
      "outline outline-1 outline-cyan-600": (params) => editable(params),
    },
    cellEditorSelector: (params) => {
      if (!editable(params)) return undefined;
      return { component: "agDateCellEditor" };
    },
    editable: (params) => editable(params),
    valueFormatter: formatCellDate,
  };
};

type TColumnAction<R = void> = (params: GroupCellRendererParams) => R;

export const getColumnDefs = ({
  insert,
  save,
  cancel,
  edit,
  item_config,
  // remove,
  options,
  onCellValueChanged,
}: {
  insert: TColumnAction;
  save: TColumnAction<Promise<void>>;
  cancel: TColumnAction;
  edit: TColumnAction;
  item_config: ItemConfig | undefined;
  // remove: TColumnAction<Promise<void>>;
  options: Partial<{ [P in keyof IRequisition]: string[] }>;
  onCellValueChanged: Partial<{ [P in keyof IRequisition]: ((event: NewValueParams) => void) | undefined }>;
}): (ColDef<IRequisition> | ColGroupDef)[] => [
  {
    children: [
      {
        headerName: "Requisition",
        children: [
          // { headerName: "Vendor", field: "vendor" },
          // { headerName: "Method", field: "method" },
          {
            headerName: "Date Req'd",
            field: "dateRequired",
            valueFormatter: formatCellDate,
            maxWidth: cellWidth.MD,
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
          },
          // { headerName: "Qty", field: "requisitionQuantity" },
          // { headerName: "$ per MT", field: "incomePerMonth" },
        ],
      },
    ],
  },
  {
    colId: "actions",
    maxWidth: cellWidth.MD,
    cellRenderer: (params: GroupCellRendererParams) => {
      if (params.data.isMainRevision === false || params.data.sourceRowName === "Balance") return null;
      return (
        <ActionsCell
          {...{ params }}
          insert={insert}
          save={save}
          cancel={cancel}
          edit={edit}
          item_config={item_config}
          // remove={remove}
        />
      );
    },
  },
  {
    headerName: "Inbound (Production)",
    children: [
      {
        headerName: "To Vendor (PO Release)",
        children: [
          {
            headerName: "Requested",
            field: "vendorOriginally",
            valueFormatter: formatCellDate,
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            maxWidth: cellWidth.MD,
          },
          {
            headerName: "Current",
            field: "vendorExpected",
            valueFormatter: formatCellDate,
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            maxWidth: cellWidth.MD,
          },
        ],
      },
      {
        headerName: "To Shipment (Ship Date)",
        children: [
          {
            headerName: "Requested",
            field: "shipmentOriginally",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            valueFormatter: formatCellDate,
            maxWidth: cellWidth.MD,
          },
          {
            headerName: "Current",
            field: "shipmentExpected",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            ...getEditableCellDateDefs((params) => [params.data?.sourceLastSync !== null]),
            onCellValueChanged: onCellValueChanged?.shipmentExpected,
            cellEditorPopup: true,
            maxWidth: cellWidth.MD,
          },
        ],
      },
      {
        headerName: "To Location (Receipt)",
        children: [
          {
            headerName: "Requested",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            field: "locationOriginally",
            valueFormatter: formatCellDate,
            maxWidth: cellWidth.MD,
          },
          {
            headerName: "Current",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            field: "locationExpected",
            valueFormatter: formatCellDate,
            maxWidth: cellWidth.MD,
          },
        ],
      },
    ],
  },
  placeholderColumn,

  {
    headerName: "Inventory (Txn Ledger)",
    children: [
      {
        headerName: "Date",
        field: "txnDate",
        cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
        maxWidth: cellWidth.MD,

        ...getEditableCellDateDefs((params) => [params.data?.sourceLastSync === null]),
        onCellValueChanged: onCellValueChanged?.txnDate,

        cellEditorPopup: true,
      },
      {
        headerName: "Qty",
        field: "quantity",
        maxWidth: cellWidth.MD,
        cellClassRules: {
          "outline outline-1 outline-cyan-600": (params) => params.data?.editable,
          "opacity-50": (params) => !params.data.isMainRevision,
        },
        cellEditorSelector: (params) => {
          const editable = params.data?.editable;

          if (!editable) return undefined;
          return {
            component: "agNumberCellEditor",
            params: {
              min: 0,
            },
          };
        },
        onCellValueChanged: onCellValueChanged?.quantity,
        editable: (params) => params.data?.editable,
        valueFormatter: ({ value, ...params }) => {
          if (!value) return "0";

          const formattedValue = valueNumberFormatter({ ...params, value: Math.abs(value) });
          return value < 0 ? `-${formattedValue}` : `+${formattedValue}`;
        },
      },
      {
        headerName: "Balance",
        children: [
          {
            headerName: "End",
            field: "endingBalance",
            maxWidth: cellWidth.SM,
            cellClassRules: {
              "text-destructive": (params) => {
                const endingBalance = params.value;
                const minBalance = params.data?.minBalance || 0;

                return endingBalance < minBalance;
              },
              "opacity-50": (params) => !params.data.isMainRevision,
            },
            valueFormatter: valueNumberFormatter,
          },
          {
            headerName: "Min",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            maxWidth: cellWidth.MD,
            field: "minBalance",
            valueFormatter: valueNumberFormatter,
          },
        ],
      },
    ],
  },
  placeholderColumn,
  {
    headerName: "Outbound",
    children: [
      {
        headerName: "To Customer",
        children: [
          {
            headerName: "Due",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            field: "departLocationExpected",
            valueFormatter: formatCellDate,
            maxWidth: 100,
          },
        ],
      },
    ],
  },
  placeholderColumn,
  {
    headerName: "Metadata",
    children: [
      {
        headerName: "Item",
        children: [
          {
            headerName: "Rev",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            field: "variant",
            maxWidth: cellWidth.SM,
          },
        ],
      },
      {
        headerName: "Vendor",
        children: [
          {
            headerName: "Name",

            field: "vendorName",
            maxWidth: cellWidth.MD,
            cellClassRules: {
              "outline outline-1 outline-cyan-600": (params) => params.data?.editable,
              "opacity-50": (params) => !params.data.isMainRevision,
            },
            cellEditorSelector: (params) => getCellEditorSelector(params, options.vendorName),
            editable: (params) => params.data?.editable,
            onCellValueChanged: onCellValueChanged?.vendorName,
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.value) {
                return "-";
              }
              return (
                <Tooltip tooltip={params.value}>
                  <span>{params.value}</span>
                </Tooltip>
              );
            },
          },
        ],
      },
      {
        headerName: "Shipment",
        children: [
          {
            headerName: "Cont. No.",
            cellClassRules: { "opacity-50": (params) => !params.data.isMainRevision },
            field: "shipmentContainerNumber",
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.value) {
                return "-";
              }
              return (
                <Tooltip tooltip={params.value}>
                  <span>{params.value}</span>
                </Tooltip>
              );
            },
          },
          {
            headerName: "Method",
            field: "shippingMethod",

            maxWidth: cellWidth.MD,
            cellEditorSelector: (params) => {
              const values = [ShippingMethodEnum.Ocean];
              if (item_config) {
                if (item_config.company.shipment_time_days_air) {
                  values.push(ShippingMethodEnum.Air);
                }
                if (item_config.company.shipment_time_days_fastvessel) {
                  values.push(ShippingMethodEnum.FastVessel);
                }
              }
              return getCellEditorSelector(params, values);
            },
            editable: (params) => params.data?.editable,
            cellClassRules: {
              "outline outline-1 outline-cyan-600": (params) => params.data?.editable,
              "opacity-50": (params) => !params.data.isMainRevision,
            },
            onCellValueChanged: onCellValueChanged?.shippingMethod,
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.value) {
                return "-";
              }
              return (
                <Tooltip tooltip={params.value}>
                  <span>{params.value}</span>
                </Tooltip>
              );
            },
          },
        ],
      },
      {
        headerName: "Location",
        children: [
          {
            headerName: "Name",
            field: "locationName",

            cellEditorSelector: (params) => getCellEditorSelector(params, options.locationName),
            editable: (params) => params.data?.editable,
            cellClassRules: {
              "outline outline-1 outline-cyan-600": (params) => params.data?.editable,
              "opacity-50": (params) => !params.data.isMainRevision,
            },
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.value) {
                return "-";
              }
              return (
                <Tooltip tooltip={params.value}>
                  <span>{params.value}</span>
                </Tooltip>
              );
            },
          },
        ],
      },
    ],
  },
  placeholderColumn,
  {
    headerName: "Source",
    field: "sourceRowName",

    minWidth: 100,
    cellClassRules: { "opacity-50": (params: CellClassParams) => !params.data?.isMainRevision },
    cellRendererSelector: (params) => {
      const link = buildSourceLink(params.data?.source_metadata);

      return {
        component: () => {
          if (!params.data) return;
          return (
            <LinkCell
              link={params.data.new ? null : link}
              value={params.value}
              tooltipActive="Go to source record"
              tooltipDisable="No source record link available"
            />
          );
        },
      };
    },
  },
];
