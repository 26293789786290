import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ReloadIcon, cn } from "@androshq/shared-ui";
import { ColDef, ColGroupDef, GridApi, GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useDeepCompareMemo } from "use-deep-compare";
import { mapColumnDefsFromResultSet, mapDataFromResultSet } from "./helpers";
import { ExtendedResultSet } from "./types";

interface Props {
  resultSet: ExtendedResultSet | null;
  columnDefsCustom?: (ColGroupDef<any> | ColDef<any, any>)[]; // Will render default columns unless this is passed
  isLoading: boolean;
  onRowClicked?: (event: RowClickedEvent) => void;
  noRowsMessage?: string;
}

const DataCardTable = ({ resultSet, isLoading, onRowClicked, noRowsMessage, columnDefsCustom }: Props) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const rowData = useMemo(() => mapDataFromResultSet(resultSet), [resultSet]);
  const columnDefs = useDeepCompareMemo(
    () => mapColumnDefsFromResultSet(resultSet),
    [mapColumnDefsFromResultSet(resultSet)],
  );

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
  };

  const onGridReady = useCallback((event: GridReadyEvent) => setGridApi(event.api), []);

  useEffect(() => {
    if (!gridApi) return;

    if (isLoading) {
      gridApi.showLoadingOverlay();
    } else if (rowData?.length === 0) {
      gridApi.showNoRowsOverlay();
      gridApi.updateGridOptions({
        rowData: [],
      });
    } else {
      gridApi.hideOverlay();
      gridApi.updateGridOptions({
        rowData,
        ...{ columnDefs: columnDefsCustom ? columnDefsCustom : columnDefs },
      });
    }
  }, [gridApi, columnDefs, columnDefsCustom, rowData, isLoading]);

  return (
    <div className={cn("ag-theme-quartz w-full h-full", { "ag-borders-none": !rowData?.length })}>
      <AgGridReact
        suppressAutoSize
        suppressCellFocus
        reactiveCustomComponents
        rowBuffer={25}
        onRowClicked={onRowClicked}
        loadingOverlayComponent={() => <ReloadIcon className="h-6 w-6 animate-spin" />}
        noRowsOverlayComponent={() => <div>{noRowsMessage || "No data available"}</div>}
        {...{ defaultColDef, onGridReady }}
      />
    </div>
  );
};

export default memo(DataCardTable);
