import {
  ColDef,
  ColGroupDef,
  GroupCellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { getDisplayNameForIdentity } from "../../../../api/standards";
import { Company, Item } from "../../../../api/types";
import ItemNameCell from "../../../../components/tables/cells/ItemNameCell";
import LinkCell from "../../../../components/tables/cells/LinkCell";
import { formatDateFromNowByMin } from "../../../../utils";
import { buildSourceLink } from "../../../items/ItemSupplyPlanningPage/helpers";
import { CAWorkflowExecution } from "../../../workflows/changeApprovals/types";
import WFExecIconStatus from "../../../workflows/components/WFExecIconStatus";

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
};

export const getColumnDefs = (items: Item[], companies: Company[]): ColGroupDef[] => [
  {
    headerName: "Last Event",
    children: [
      {
        field: "lastEventTime",
        headerName: "Time",
        valueFormatter: (params: ValueFormatterParams) => formatDateFromNowByMin(params.value),
        valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
          params.data?.state.event_history[0].time,
      },
      {
        field: "lastEventType",
        headerName: "Type",
        valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
          params.data?.state.event_history[0].data.workflow_event_type,
      },
      {
        field: "lastEventTriggeredBy",
        headerName: "Triggered By",
        valueGetter: (params: ValueGetterParams<CAWorkflowExecution, number>) => {
          if (params.data?.state.event_history[0].data.triggered_by.id) {
            return getDisplayNameForIdentity(params.data?.state.event_history[0].data.triggered_by);
          }
          return "";
        },
      },
    ],
  },
  {
    headerName: "",
    children: [
      {
        field: "approval_status",
        headerName: "Status",
        maxWidth: 60,
        cellRenderer: (params: GroupCellRendererParams) => {
          return (
            <WFExecIconStatus
              value={params.data.state.approval_status}
              currentApprover={params.data.search_attributes?.current_approver}
            />
          );
        },
      },
    ],
  },
  {
    headerName: "",
    children: [
      {
        field: "company",
        headerName: "Company",
        valueGetter: (params: ValueGetterParams) => {
          const companyId = params.data?.state.version_history[0].data.company_id;
          const companyName = companies.find((company) => company.id === companyId)?.name;
          return companyName || "Unknown";
        },
      },
      {
        field: "item",
        headerName: "Item",
        cellRenderer: (params: ValueGetterParams) => {
          const itemId = params.data?.state.version_history[0].data.item_id;
          const item = items.find((item) => item.id === itemId);
          if (!item) {
            return "";
          }
          return (
            <ItemNameCell
              item_name={item.name}
              item_name_prefix={item.source_metadata.item_name_prefix}
              item_variant={item.variant}
            />
          );
        },
      },
    ],
  },
  {
    headerName: "Record",
    children: [
      {
        field: "source_row_name",
        headerName: "Name",
        minWidth: 200,
        valueGetter: (params: ValueGetterParams<CAWorkflowExecution, string>) =>
          params.data?.state.version_history[0]?.data.source_row_name,
        cellRendererSelector: (params) => {
          const link = buildSourceLink(params.data?.state.version_history[0].data.rows[0].source_metadata);
          return {
            component: () => {
              return (
                <LinkCell
                  link={link}
                  value={params.value}
                  tooltipActive="Go to source record"
                  tooltipDisable="No source record link available"
                />
              );
            },
          };
        },
      },
    ],
  },
];
