import { AbstractModuleApiClient, ApiClientResponse } from "@androshq/core/browser-client";
import { format } from "date-fns";
import queryString from "query-string";
import { MODULE_API_ROUTER_PATH } from "../../common";
import { CAWorkflowEventRequestData, CAWorkflowExecution, RowGroup } from "../pages/workflows/changeApprovals/types";
import { ERPWriteWorkflowEventRequestData } from "../pages/workflows/erpWrite/types";
import { WorkflowName } from "../pages/workflows/types";
import * as messages from "./messages";
import { StateTransitionFull, TransitionFull } from "./types";
import { Company, ItemWithConfig, State, User, Vendor } from "./types";

export type GetStatesParams = {
  state_type?: string;
};

export type GetForecastRecommendationsParams = {
  leadTimeWeeksMin?: number;
  leadTimeWeeksMax?: number;
  companyIds?: string[];
};

export type GetRequisitionRecommendationsParams = {
  weekMin?: number;
  weekMax?: number;
  companyIds?: string[];
};

export type OverrideForecastsParams = {
  itemId: number;
  fromStateId: number;
  overrideWindowStart: Date;
  overrideWindowEnd: Date;
  overrideQuantity: number;
};

export interface WorkflowExecutionsFilters {
  [key: string]: string[];
}

export type WorkflowExecutionsSearchQueryParams = {
  filters: Partial<WorkflowExecutionsFilters>;
  order_by: "start_time" | "last_modified";
  order_by_desc: boolean;
  limit: number;
  offset: number;
};

export type SyncedLinesSearchQueryParams = {
  filters: { line_parent_name: string[] };
  limit: number;
  offset: number;
};



class SopApiClient extends AbstractModuleApiClient {
  constructor() {
    super(MODULE_API_ROUTER_PATH, import.meta.env.SERVER_API_BASE_URL);
  }

  companies = {
    getAll: () =>
      this.makeRequest<Company[]>({
        path: "/v1/companies",
      }),
  };

  vendors = {
    getAll: () =>
      this.makeRequest<Vendor[]>({
        path: "/v1/states?state_type=vendor",
      }),
  };
  users = {
    getAll: () =>
      this.makeRequest<User[]>({
        path: "/v1/users",
      }),
  };

  states = {
    getAll: <T extends State[]>(params?: GetStatesParams) => {
      return this.makeRequest<T>({
        path: queryString.stringifyUrl({ url: "/v1/states", query: params }),
      });
    },
  };

  config = {
    update: (scopeCompanyId: number, key: string, value: number) => {
      const body = {
        scope_company_id: scopeCompanyId,
        key,
        value,
      };

      return this.makeRequest<string>({
        path: "/v1/config",
        requestInit: {
          method: "PUT",
          body: JSON.stringify(body),
        },
      });
    },
  };

  items = {
    getAll: () =>
      this.makeRequest<ItemWithConfig[]>({
        path: "/v1/items",
      }),

    item: {
      getStateTransitions: (itemId: number, stateId?: number) => {
        return this.makeRequest<StateTransitionFull[]>({
          path: queryString.stringifyUrl({
            url: `/v1/items/${itemId}/state-transitions`,
            query: { state_id: stateId },
          }),
        });
      },

      overrideForecasts: (params: OverrideForecastsParams) => {
        const query = {
          from_state_id: params.fromStateId,
          override_window_start: format(params.overrideWindowStart, "yyyy-MM-dd"),
          override_window_end: format(params.overrideWindowEnd, "yyyy-MM-dd"),
          override_quantity: params.overrideQuantity,
        };

        return this.makeRequest<string>({
          path: queryString.stringifyUrl({ url: `/v1/items/${params.itemId}/upsert-override`, query }),
          requestInit: {
            method: "PUT",
          },
        });
      },

      states: {
        getAll: (itemId: number, params?: GetStatesParams) => {
          return this.makeRequest<State[]>({
            path: queryString.stringifyUrl({ url: `/v1/items/${itemId}/states`, query: params }),
          });
        },
      },
    },
  };

  workflows = {
    executions: {
      search: (params?: WorkflowExecutionsSearchQueryParams) => {
        return this.makeRequest<CAWorkflowExecution[]>({
          path: "/v1/workflows/executions/search",
          requestInit: {
            method: "POST",
            body: JSON.stringify(params),
          },
        });
      },
      signal: async <T extends CAWorkflowEventRequestData | ERPWriteWorkflowEventRequestData>(
        payload: T,
      ): Promise<ApiClientResponse<T extends CAWorkflowEventRequestData ? CAWorkflowExecution : undefined>> => {
        let toastId;
        if (payload.workflow_type_name == WorkflowName.ChangeApprovalsWorkflow) {
          toastId = messages.beforeCAWorkflowSignal(payload as CAWorkflowEventRequestData);
        } else if (payload.workflow_type_name == WorkflowName.ERPWriteWorkflow) {
          toastId = messages.beforeCAWorkflowSignal(payload as ERPWriteWorkflowEventRequestData);
        }

        if (!toastId) throw Error("No toastId found from before__WorkflowSignal... should not happen..."); // @Vasyl this will throw until you implement the beforeERPWriteWorkflowSignal function (and the one after the request, afterERPWriteWorkflowSignal)

        const res = await this.makeRequest<T extends CAWorkflowEventRequestData ? CAWorkflowExecution : undefined>({
          path: "/v1/workflows/executions",
          requestInit: {
            method: "POST",
            body: JSON.stringify(payload),
          },
        });

        if (payload.workflow_type_name == WorkflowName.ChangeApprovalsWorkflow) {
          messages.afterCAWorkflowSignal(toastId, payload, res as ApiClientResponse<CAWorkflowExecution>);
        } else if (payload.workflow_type_name == WorkflowName.ERPWriteWorkflow) {
          messages.afterCAWorkflowSignal(toastId, payload, res as ApiClientResponse<CAWorkflowExecution>);
        }

        return res;
      },
    },

  };

  lines = {
    search: (params?: SyncedLinesSearchQueryParams) => {
      return this.makeRequest<TransitionFull[]>({
        path: "/v1/lines/search",
        requestInit: {
          method: "POST",
          body: JSON.stringify(params),
        },
      });
    },
    line: (params: { line_id_source: string, line_parent_id_source: string }) => {
      return this.makeRequest<RowGroup>({
        path: `/v1/lines?line_id_source=${params.line_id_source}&line_parent_id_source=${params.line_parent_id_source}`,
        requestInit: {
          method: "GET",
        },
      });
    },
  }

}

export const sopApiClient = new SopApiClient();
