import { useCallback } from "react";
import { addDays, subDays } from "date-fns";
import _ from "lodash";
import { useItems } from "../../api/hooks/items";
import { ItemConfig, ItemVendorConfig, ItemWithConfig, Location, ShippingMethodEnum, Vendor } from "../../api/types";
import { getIsVendorActual } from "../../pages/workflows/changeApprovals/CAWorkflowModal/CAModalSectionInputs/utils";
import { StateTransition } from "../../pages/workflows/changeApprovals/types";
import { useFetchStates } from "../states/useFetchStates";

const getConfigs = (item: ItemWithConfig | undefined, vendor: Vendor | undefined) => {
  if (!item) throw new Error("Item not found");

  const itemConfig = item.config;
  const vendorId = vendor?.id;

  if (!vendorId) throw new Error("Vendor not found");
  if (!(vendorId in itemConfig.vendor)) throw new Error("Vendor info not found in item config");

  const vendorConfig = itemConfig.vendor[vendorId];

  return { itemConfig, vendorConfig };
};

const getTransitionsTimeDays = (
  itemConfig: ItemConfig,
  vendorConfig: ItemVendorConfig,
  shippingMethod: `${ShippingMethodEnum}`,
) => {
  const vendorTimeDays = vendorConfig.vendor_time_days;

  const shipmentTimeDays = {
    [ShippingMethodEnum.Air]: itemConfig.company?.shipment_time_days_air || 0,
    [ShippingMethodEnum.FastVessel]: itemConfig.company?.shipment_time_days_fastvessel || 0,
    [ShippingMethodEnum.Ocean]: vendorConfig.shipment_time_days,
  }[shippingMethod];

  return { vendorTimeDays, shipmentTimeDays };
};

export const useStateTransition = () => {
  const { data: items } = useItems();
  const { data: vendors } = useFetchStates<Vendor[]>({ state_type: "vendor" });
  const { data: locations } = useFetchStates<Location[]>({ state_type: "location" });

  const isLoading = !(items && vendors && locations);

  // Legacy - Left it here to not break Supply planning dates calculations
  const getTransitionDates = useCallback(
    (transition: {
      itemId: number;
      vendorName: string;
      shippingMethod: `${ShippingMethodEnum}`;

      locationDate?: string;
      shipmentDate?: string;
      vendorDate?: string;
    }) => {
      const item = _.find(items, { id: +transition.itemId! });
      const vendor = vendors?.find((vendor) => vendor.name === transition.vendorName);

      const { itemConfig, vendorConfig } = getConfigs(item, vendor);
      const { vendorTimeDays, shipmentTimeDays } = getTransitionsTimeDays(
        itemConfig,
        vendorConfig,
        transition.shippingMethod,
      );

      const locationDate = transition?.locationDate ?? addDays(transition?.shipmentDate || "", shipmentTimeDays);
      const shipmentDate = transition?.shipmentDate ?? subDays(locationDate, shipmentTimeDays);
      const vendorDate = transition?.vendorDate ?? subDays(shipmentDate, vendorTimeDays);

      return { vendorDate, shipmentDate, locationDate };
    },
    [items, vendors],
  );

  const calculateRowDatesFromConfig = useCallback(
    (
      itemId: number,
      stateTransitions: StateTransition[],
      data: {
        vendorName: string;
        shippingMethod: `${ShippingMethodEnum}`;
        shipmentDate?: Date;
      },
    ) => {
      const vendorTransition = _.find(stateTransitions, { state_type: "vendor" });
      const shipmentTransition = _.find(stateTransitions, { state_type: "shipment" });
      const locationTransition = _.find(stateTransitions, { state_type: "location" });
      const isVendorActual = getIsVendorActual(stateTransitions);
      const item = _.find(items, { id: itemId });
      const vendor = vendors?.find((vendor) => vendor.name === data.vendorName);
      const { itemConfig, vendorConfig } = getConfigs(item, vendor);
      console.log(data);

      const { vendorTimeDays, shipmentTimeDays } = getTransitionsTimeDays(
        itemConfig,
        vendorConfig,
        data.shippingMethod,
      );
      let vendorDate = new Date(vendorTransition?.at || "");
      let shipmentDate = new Date(shipmentTransition?.at || "");
      let locationDate = new Date(locationTransition?.at || "");
      // Start dates calculation from shipment if shipmentDate was passed i.e. it was changed
      if (data?.shipmentDate) {
        shipmentDate = data.shipmentDate;
        locationDate = addDays(data.shipmentDate, shipmentTimeDays);
        if (!isVendorActual) {
          vendorDate = subDays(shipmentDate, vendorTimeDays);
        }
      }

      return { vendorDate, shipmentDate, locationDate };
    },
    [items, vendors],
  );

  const getStateById = useCallback(
    (stateId: number | undefined) => _.find([...(vendors || []), ...(locations || [])], { id: stateId }),
    [vendors, locations],
  );

  return { vendors, locations, items, calculateRowDatesFromConfig, getTransitionDates, getStateById, isLoading };
};
