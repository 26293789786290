import { Button, cn } from "@androshq/shared-ui";
import { Link2Icon, LinkBreak2Icon } from "@androshq/shared-ui";
import Tooltip from "../../messages/Tooltip";

interface Props {
  link: string | null;
  tooltipActive: string;
  tooltipDisable: string;
  value: string;
}

export default function LinkCell({ link, tooltipActive, tooltipDisable, value }: Props) {
  const handleLinkClick = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <Tooltip side="left" tooltip={link ? tooltipActive : tooltipDisable}>
      <span tabIndex={0} data-link>
        <Button
          data-testid="source_name"
          className={cn("font-normal text-xs p-0 pb-1.5 flex items-center gap-1", {
            " pointer-events-none": link === null,
          })}
          /* @ts-expect-error bug in button component in @androshq/shared-ui */
          size={"sm"}
          onClick={handleLinkClick}
          variant="link"
        >
          {link === null ? <LinkBreak2Icon className="w-3 h-3" /> : <Link2Icon className="w-3 h-3" />}

          {value}
        </Button>
      </span>
    </Tooltip>
  );
}
