import { Query } from "@cubejs-client/core";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { addDays, format } from "date-fns";
import _ from "lodash";
import ItemNameCell from "../../../tables/cells/ItemNameCell";
import { BuiltQueryConfig, DisplayConfig, DynamicInputs, InputConfigs } from "../DataCard";

const customColumnDefs: (ColGroupDef<any> | ColDef<any, any>)[] = [
  {
    field: "inventory_projected_overages-company_name",
    headerName: "Company",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
  },
  {
    field: "item_name",
    headerName: "Item",
    flex: 1,
    cellClass: "cursor-pointer",
    width: 120,
    maxWidth: 120,
    cellRendererSelector: (params) => {
      return {
        component: () => {
          if (!params.data) return;
          return (
            <ItemNameCell
              item_name={
                Object.keys(params.data).find((key) => key.endsWith("item_name"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name"))!]
                  : ""
              }
              item_name_prefix={
                Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_name_prefix"))!]
                  : ""
              }
              item_variant={
                Object.keys(params.data).find((key) => key.endsWith("item_variant"))
                  ? params.data[Object.keys(params.data).find((key) => key.endsWith("item_variant"))!]
                  : ""
              }
            />
          );
        },
      };
    },
  },
  {
    field: "excess_weight",
    headerName: "Excess Wt Proj.",
    cellClass: "cursor-pointer",
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    valueGetter: (params) => {
      const item_wt =
        parseFloat(params.data["inventory_projected_overages-excess_wt_at_lead_time"]) /
        (params.data["inventory_projected_overages-ending_balance"] -
          params.data["inventory_projected_overages-inv_qty_min_all_loc"]);
      return {
        excess_wt_at_lead_time: params.data["inventory_projected_overages-excess_wt_at_lead_time"],
        ending_balance: params.data["inventory_projected_overages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        inv_wt_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"] * item_wt,
        excess_pct:
          parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
            Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
          1,
      };
    },
    cellRendererSelector: (params) => {
      console.log(params.value);
      return {
        component: () => {
          if (!params.data) return;
          const maxPct = 5;
          const warnWt = 10;
          const warnPctWtMin = 5;
          const warnPct = 1;
          const excessPctHundreds = Math.round(Math.min(params.value.excess_pct, maxPct) * 100);
          const plusStr = params.value.excess_pct > maxPct ? "+" : "";
          let statusColorClass = null;
          if (params.value.excess_wt_at_lead_time > warnWt || params.value.excess_pct > warnPct) {
            if (params.value.excess_wt_at_lead_time > warnWt && params.value.excess_pct > warnPct) {
              statusColorClass = "text-orange-700";
            } else if (params.value.excess_wt_at_lead_time > warnPctWtMin && params.value.excess_pct > warnPct) {
              statusColorClass = "text-amber-500";
            }
          }
          return (
            <div className={`inline-flex ${statusColorClass ? statusColorClass : ""}`}>
              <div className="mr-2 w-10 text-right">{`${params.value.excess_wt_at_lead_time} MT`}</div>
              <span
                className={`${statusColorClass ? statusColorClass : "text-muted-foreground"}`}
              >{`+${excessPctHundreds}%${plusStr}`}</span>
            </div>
          );
        },
      };
    },
  },
  {
    field: "total_qty",
    headerName: "Current Proj. Balance",
    cellClass: "cursor-pointer",
    flex: 1,
    minWidth: 110,
    width: 110,
    maxWidth: 130,
    valueGetter: (params) => {
      return {
        excess_qty_at_lead_time:
          params.data["inventory_projected_overages-ending_balance"] -
          params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        ending_balance: params.data["inventory_projected_overages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        lead_time_days: params.data["inventory_projected_overages-lead_time_days"],
        excess_pct:
          parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
            Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
          1,
      };
    },
    cellRendererSelector: (params) => {
      console.log(params.value);
      return {
        component: () => {
          if (!params.data) return;
          return (
            <div className="inline-flex">
              <div className="mr-2 w-6 text-right">{`${params.value.ending_balance}`}</div>
              <div className="text-muted-foreground mr-1">{`vs min`}</div>
              <div className="mr-2 w-6 text-left text-muted-foreground">{`${params.value.inv_qty_min_all_loc}`}</div>
            </div>
          );
        },
      };
    },
  },
  {
    field: "inventory_projected_overages-bal_snapshot_qty_all_loc",
    headerName: "Current Bal.",
    cellClass: "cursor-pointer",
    flex: 1,
    minWidth: 90,
    width: 90,
    maxWidth: 90,
  },
  {
    field: "inventory_projected_overages-inflow_cumulative",
    headerName: "On Odr/Shp",
    cellClass: "cursor-pointer",
    flex: 1,
    minWidth: 90,
    width: 90,
    maxWidth: 90,
  },
  {
    field: "date_projected",
    headerName: "Date Proj. (Lead Time)",
    cellClass: "cursor-pointer",
    flex: 1,
    valueGetter: (params) => {
      return {
        excess_qty_at_lead_time:
          params.data["inventory_projected_overages-ending_balance"] -
          params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        ending_balance: params.data["inventory_projected_overages-ending_balance"],
        inv_qty_min_all_loc: params.data["inventory_projected_overages-inv_qty_min_all_loc"],
        lead_time_days: params.data["inventory_projected_overages-lead_time_days"],
        excess_pct:
          parseFloat(params.data["inventory_projected_overages-ending_balance"]) /
            Math.max(params.data["inventory_projected_overages-inv_qty_min_all_loc"], 1) -
          1,
      };
    },
    cellRendererSelector: (params) => {
      console.log(params.value);
      return {
        component: () => {
          if (!params.data) return;
          const dateProjected = addDays(new Date(), params.value.lead_time_days);
          return (
            <div className="inline-flex">
              <div className="mr-2 w-12 text-left">{`${format(dateProjected, "M/d/yy")}`}</div>
              <div className="text-left text-muted-foreground">{` in ${params.value.lead_time_days} days LT`}</div>
            </div>
          );
        },
      };
    },
  },
];

export const getQueryParams = (
  selectedCompanyIds: string[],
  companies: any[],
): [DisplayConfig, BuiltQueryConfig[], InputConfigs] => {
  const dynamicInputs: DynamicInputs = {
    timeDimensions: [],
    filters: [
      {
        inputDataKey: "company_ids",
        member: "inventory_projected_overages.company_id",
        operator: "equals",
      },
    ],
  };

  const baseQuery: Query = {
    dimensions: [
      "inventory_projected_overages.company_name",
      "inventory_projected_overages.item_name",
      "inventory_projected_overages.item_name_prefix",
      "inventory_projected_overages.item_variant",
      "inventory_projected_overages.item_id",
      "inventory_projected_overages.excess_wt_at_lead_time",
      "inventory_projected_overages.lead_time_days",
      "inventory_projected_overages.bal_snapshot_qty_all_loc",
      "inventory_projected_overages.inflow_cumulative",
      "inventory_projected_overages.outflow_cumulative",
      "inventory_projected_overages.ending_balance",
      "inventory_projected_overages.inv_qty_min_all_loc",
    ],
    order: [["inventory_projected_overages.excess_wt_at_lead_time", "desc"]],
  };
  const displayConfig: DisplayConfig = {
    cardTitle: "Most Overstocked Items",
    outputType: "table",
    noRowsMessage: selectedCompanyIds.length ? "No overstocked items found" : "Select a customer to see results",
    columnDefsCustom: customColumnDefs,
  };

  const queryConfigs: BuiltQueryConfig[] = [
    {
      baseQuery: baseQuery,
      dynamicInputs: dynamicInputs,
    },
  ];

  const inputConfigs: InputConfigs = {
    company_ids: {
      disabled: true,
      inputDataType: "multiSelect",
      selectedIds: selectedCompanyIds,
      options: companies,
    },
  };
  return [displayConfig, queryConfigs, inputConfigs];
};
