import { useMemo, useState } from "react";
import { useAuth } from "@androshq/auth/browser-client";
import { Permission, checkUserForPermissions } from "@androshq/auth/common";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  DownloadIcon,
  LayoutIcon,
  MagnifyingGlassIcon,
  Share2Icon,
  cn,
} from "@androshq/shared-ui";
import { GridApi } from "ag-grid-community";
import queryString from "query-string";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WorkflowExecutionsFilters, WorkflowExecutionsSearchQueryParams, sopApiClient } from "../../api";
import { PageContent } from "../../components/navigation/PageContent";
import { useCachedData } from "../../hooks/use-cached-data";
import { useFetchWorkflowExecutions } from "../../hooks/workflows/useFetchWorkflowExecutions";
import { IAction, WorkflowExecutionsActionBar } from "./WorkflowExecutionsActionBar";
import WorkflowExecutionsTable from "./WorkflowExecutionsTable";
import CAWorkflowModal from "./changeApprovals/CAWorkflowModal";
import PCLEditWorkflowModal from "./changeApprovals/CAWorkflowsModalNew/CAWorkflowNew";
import SearchDocument from "./changeApprovals/CAWorkflowsModalNew/searchDocument";
import { filterGenerators } from "./changeApprovals/caWorkflowFilters";
import ConfirmSyncModal from "./erpWrite/ConfirmSyncModal/ConfirmSyncModal";
import { ERPWriteWorkflowEventRequestData, EventTypeEnum } from "./erpWrite/types";
import {
  EXPORT_LINES_TOOLTIP,
  SEARCH_SYNCED_WORKFLOWS_TOOLTIP,
  SELECT_COLUMNS_TOOLTIP,
  SYNC_LINES_TOOLTIP_MSG_DISABLED_NONE_SELECTED,
} from "./messages";
import { WorkflowName, WorkflowReference } from "./types";

const PAGE_ROWS_LIMIT = 100;

export default function WorkflowExecutionsPage() {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { user } = useAuth();
  const { allowed, reason } = checkUserForPermissions(user, [Permission.APP_DATA_PURCHASE_SYNC]);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);
  const [isSearchDocumentOpen, setIsSearchDocumentOpen] = useState<boolean>(false);

  const [isPossibleSyncData, setIsPossibleSyncData] = useState<{ isPossible: boolean; reason: string }>({
    isPossible: false,
    reason: SYNC_LINES_TOOLTIP_MSG_DISABLED_NONE_SELECTED,
  });

  const [searchParams] = useSearchParams();
  const filterKeys: string[] = useMemo(
    () => [
      "search_attributes.current_approver.id",
      "search_attributes.approval_status",
      "search_attributes.approvers[*].user.id",
    ],
    [],
  );

  const page = searchParams.get("page");

  const navigate = useNavigate();
  const location = useLocation();

  const filtersParams: WorkflowExecutionsSearchQueryParams = useMemo(() => {
    const filters: WorkflowExecutionsFilters = {
      workflow_type_name: [WorkflowName.ChangeApprovalsWorkflow],
    };

    filterKeys.forEach((filterKey) => {
      const paramValue = searchParams.get(filterKey);
      if (paramValue) {
        filters[filterKey] = paramValue.split(",");
      }
    });
    return {
      filters,
      order_by: "start_time",
      order_by_desc: true,
      limit: PAGE_ROWS_LIMIT,
      offset: page ? +page * PAGE_ROWS_LIMIT : 0,
    } as WorkflowExecutionsSearchQueryParams;
  }, [searchParams, page, filterKeys]);

  const { users, items, companies, vendors } = useCachedData();

  // CONFIGURE FILTERS SHOWN ... TODO handle state management as part of this

  const filters = useMemo(
    () => [
      filterGenerators["search_attributes.approval_status"](null),
      filterGenerators["search_attributes.current_approver.id"](users.data || []),
      filterGenerators["search_attributes.approvers[*].user.id"](users.data || []),
    ],
    [users.data],
  );

  const {
    data: workflowExecutions,
    invalidate: invalidateWorkflowExecution,
    isLoading,
  } = useFetchWorkflowExecutions(filtersParams);

  const handleNextClick = () => {
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.page) {
      parsedQuery["page"] = (+parsedQuery.page + 1).toString();
    } else {
      parsedQuery["page"] = "1";
    }
    const newQueryString = queryString.stringify(parsedQuery);
    navigate(`${location.pathname}?${newQueryString}`);
  };

  const handlePreviousClick = () => {
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.page && +parsedQuery.page > 0) {
      parsedQuery["page"] = (+parsedQuery.page - 1).toString();
    }
    const newQueryString = queryString.stringify(parsedQuery);
    navigate(`${location.pathname}?${newQueryString}`);
  };

  const rowData = useMemo(() => workflowExecutions || [], [workflowExecutions]);

  const exportTableToExcel = () => {
    gridApi?.exportDataAsExcel();
  };
  const controllColumns = () => {
    gridApi?.isToolPanelShowing() ? gridApi?.closeToolPanel() : gridApi?.openToolPanel("columns");
  };

  const syncLinesToERP = async () => {
    if (!gridApi) return;

    setIsSyncing(true);

    const syncPayloadData: WorkflowReference[] = gridApi.getSelectedRows().map((row) => {
      return {
        workflow_id: row.workflow_id,
        workflow_run_id: row.workflow_run_id,
        workflow_type_name: WorkflowName.ChangeApprovalsWorkflow,
      };
    });

    const syncPayload: ERPWriteWorkflowEventRequestData = {
      workflow_event_type: EventTypeEnum.START,
      workflow_type_name: "ERPWriteWorkflow",
      workflow_type_version: "1.0",
      data: syncPayloadData,
    };
    try {
      const response = await sopApiClient.workflows.executions.signal(syncPayload);
      if (response.success) {
        invalidateWorkflowExecution();
        return true;
      }
    } finally {
      setIsSyncing(false);
    }
  };

  const actions: IAction[] = [
    {
      title: "Start New Edit",
      isLoading: false,
      isDisabled: false,
      onClick: () => setIsSearchDocumentOpen(!isSearchDocumentOpen),
      tooltip: SEARCH_SYNCED_WORKFLOWS_TOOLTIP,
      icon: <MagnifyingGlassIcon className="mr-2" />,
    },

    {
      title: "Select Columns",
      isLoading: false,
      isDisabled: false,
      onClick: controllColumns,
      tooltip: SELECT_COLUMNS_TOOLTIP,
      icon: <LayoutIcon className="mr-2" />,
    },
    {
      title: "Export Lines",
      isLoading: false,
      isDisabled: false,
      tooltip: EXPORT_LINES_TOOLTIP,
      onClick: exportTableToExcel,
      icon: <DownloadIcon className="mr-2" />,
    },
    {
      title: "Sync Lines to ERP",
      isLoading: false,
      tooltip: allowed ? isPossibleSyncData.reason : reason || "",
      isDisabled: !isPossibleSyncData.isPossible || !allowed || isSyncing,
      onClick: () => setIsSyncModalOpen(!isSyncModalOpen),
      dataTestId: "open-sync-window",
      icon: <Share2Icon className="mr-2" />,
    },
  ];
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(location.search);

  const workflowIdAndRunId = useMemo(() => {
    if ((queryParams["workflow_id"], queryParams["workflow_run_id"])) {
      return {
        workflow_id: queryParams["workflow_id"]?.toString(),
        workflow_run_id: queryParams["workflow_run_id"].toString(),
      };
    } else {
      return null;
    }
  }, [queryParams["workflow_id"], queryParams["workflow_run_id"]]);

  const sourceIdAndLineId = useMemo(() => {
    if (queryParams["line_parent_id_source"] && queryParams["line_id_source"]) {
      return {
        line_id_source: queryParams["line_id_source"]?.toString(),
        line_parent_id_source: queryParams["line_parent_id_source"].toString(),
      };
    } else {
      return null;
    }
  }, [queryParams["line_id_source"], queryParams["line_parent_id_source"]]);

  return (
    <>
      <WorkflowExecutionsActionBar filters={filters} actions={actions} />
      <PageContent className="grid">
        {companies.data && items.data && vendors.data && (
          <div>
            {workflowIdAndRunId && (
              <CAWorkflowModal workflowIdAndRunId={workflowIdAndRunId} invalidateData={invalidateWorkflowExecution} />
            )}
            {sourceIdAndLineId && (
              <PCLEditWorkflowModal
                invalidateWorkflowExecution={invalidateWorkflowExecution}
                sourceIdAndLineId={sourceIdAndLineId}
              />
            )}
            <SearchDocument
              isSearchDocumentOpen={isSearchDocumentOpen}
              setIsSearchDocumentOpen={setIsSearchDocumentOpen}
              items={items.data}
            />
            <ConfirmSyncModal
              gridApi={gridApi}
              isSyncModalOpen={isSyncModalOpen}
              setIsSyncModalOpen={setIsSyncModalOpen}
              syncLinesToERP={syncLinesToERP}
            />
            <WorkflowExecutionsTable
              gridApi={gridApi}
              isSyncing={isSyncing}
              setGridApi={setGridApi}
              rowData={rowData}
              isLoading={isLoading}
              companies={companies.data}
              items={items.data}
              vendors={vendors.data}
              setIsPossibleSyncData={setIsPossibleSyncData}
            />
            <div
              className={cn(
                "flex justify-end gap-2.5 p-2.5",
                rowData?.length === PAGE_ROWS_LIMIT ? "border-none" : "border-t",
              )}
            >
              <Button
                disabled={!rowData || page === null || page.toString() === "0"}
                // @ts-expect-error TODO fix common types to prevent this
                variant="outline"
                className=" flex gap-1.5"
                onClick={handlePreviousClick}
              >
                <ArrowLeftIcon />
                Previous
              </Button>
              <Button
                disabled={!rowData || rowData?.length < PAGE_ROWS_LIMIT}
                // @ts-expect-error TODO fix common types to prevent this
                variant="outline"
                className={"flex gap-1.5"}
                onClick={handleNextClick}
              >
                Next
                <ArrowRightIcon />
              </Button>
            </div>
          </div>
        )}
      </PageContent>
    </>
  );
}
